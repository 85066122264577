<template>
  <!-- 公司推介材料 -->
  <div>
    <div>
      <div
        class="banner"
        id="investor_banner1"
        :style="{ backgroundImage: 'url(' + banner + ')' }"
      >
        <!-- <img :src="banner"/> -->
        <span
          >{{ $t("indexnav.Corporate Presentation")
          }}<i
            :style="{
              display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
            }"
            >Corporate Presentation</i
          ></span
        >
      </div>

      <section class="ab-item1">
        <!-- <a href="/">网站首页</a> > <a href="/ir/default.html">投资者关系</a> >
        {{ $t("indexnav.Corporate Presentation") }} -->
        <bread-crumb />
      </section>
      <section>
        <div id="iframeContainer"></div>
      </section>
      <!-- 选择年份 -->
      <section v-if="false" class="content" id="iframeContainer">
        <div>
          <!-- 当前年份 -->
          <div>{{ nowYear }}</div>
          <!-- 下拉框 -->
          <ul>
            <li @click="checkyear">
              <span>{{ $t("responsibility.Select Year") }}</span>
            </li>
            <ul
              :style="{
                height: showyear ? 47 * yearList.length + 'px' : '0px',
                transition: 'all,0.4s',
              }"
            >
              <li
                v-for="item in yearList"
                :key="item.id"
                @click="Clickyear(item)"
              >
                <i>{{ item }}</i>
              </li>
            </ul>
          </ul>
        </div>
        <div class="select-content"></div>
      </section>
      <section v-if="false">
        <div class="select-content">
          <ul class="comm-list m-list no-type">
            <!-- loading -->
            <div class="loading_box" v-if="showLoading"></div>
            <li v-for="i in dataList" :key="i.id">
              <span>{{ i.pulishDate }}</span>
              <span
                ><a :href="filterLang(i, 'FileUrl')" target="_blank">{{
                  filterLang(i, "Title")
                }}</a></span
              >
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import moment from "dayjs";
import { CaiLiaoData } from "@/api/investors";
import { filterLang } from "@/utils/LangChange";
import { getyearList, Clickyear } from "@/utils/clickyear";
import { PageType } from "@/utils/constant";
import { getAction } from "@/api/manage";
import { ifarmeMixin } from "../mixin/ifarmeMixin";

export default {
  mixins: [ifarmeMixin],
  data() {
    return {
      showyear: false,
      yearList: [],
      dataList: [],
      showLoading: true,
      resList: [],
      nowYear: "",
      banner: "",
      izh: "https://jinxinfertility.website.wisdomir.com/sc/ir_presentations.php",
      itr: "https://jinxinfertility.website.wisdomir.com/tc/ir_presentations.php",
      ien: "https://jinxinfertility.website.wisdomir.com/en/ir_presentations.php",
    };
  },
  created() {
    let self = this;

    // 初始化banner
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.GONGSITUIJIECAILIAO,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
        if (res.result.records[0].isMultipatLanguge !== "0") {
          if (this.$store.state.nowlanguage == "zh")
            self.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            self.banner = res.result.records[0].tranditionalImg;
          if (this.$store.state.nowlanguage == "en")
            self.banner = res.result.records[0].enImg;
        } else {
          self.banner = res.result.records[0].sampleImg;
        }
      }
    });

    this.loading = true;
    // 拿到所有数据
    CaiLiaoData({ sort: 1 }).then((res) => {
      this.showLoading = false;
      console.log(res);
      this.resList = res.result;

      // 获取年份列表
      res.result.map((res) => {
        // 添加时间数据 去重
        let year = moment(res.pulishDate).year();
        if (self.yearList.indexOf(year) === -1) {
          self.yearList.push(year);
        }
      });

      // 对年份进行降序排列
      self.yearList = self.yearList.sort((a, b) => {
        return b - a; // 按照从大到小排列
      });
      // 获取显示年份
      self.nowYear = moment(new Date()).year();
      if (self.dataList.length > 0) {
        self.nowYear = this.dataList[0];
      }
      self.nowYear = self.yearList[0];
      self.dataList = Clickyear(self.resList, self.nowYear);
    });
  },
  methods: {
    filterLang,
    checkyear() {
      this.showyear = !this.showyear;
    },
    Clickyear(year) {
      this.nowYear = year;
      this.showyear = !this.showyear;
      this.dataList = Clickyear(this.resList, year);
      console.log(this.dataList);
    },
  },
  mounted() {
    if (this.$store.state.nowlanguage == "zh") {
      this.setIframe(this.izh);
    }
    if (this.$store.state.nowlanguage == "tr") {
      this.setIframe(this.itr);
    }
    if (this.$store.state.nowlanguage == "en") {
      this.setIframe(this.ien);
    }
  },
};
</script>

<style scoped lang=less>
.banner {
  position: relative;
}

.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section,
article {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 520px) {
  ul.comm-list li span {
    display: block;
    width: 100% !important;
    margin-left: 0px !important;
  }
}
/* 选择年份 */
.content {
  > div:first-child {
    > div {
      display: block;
      float: left;
      color: #18325f;
      font-size: 1.3rem;
    }
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
    border-bottom: 5px solid #18325f;
    > ul {
      position: relative;
      > li {
        color: #fff;
        cursor: pointer;
        background: #18325f;
        padding: 10px 58px 11px 15px;
        position: relative;
        > span {
          font-size: 14px;
        }
      }
      > ul {
        width: 100%;
        position: absolute;
        left: 0;
        text-align: center;
        border-radius: 0px !important;
        overflow: hidden;
        > li {
          font-size: 14px;
          padding: 14px 0;
          color: #fff;
          cursor: pointer;
          background: #18325f;
        }
        > li:hover {
          background: #747474 !important;
        }
      }
    }
  }
}
/* 三角 */
.content div > ul > li > span:after {
  content: "";
  width: 0px;
  height: 0px;
  display: block;
  position: absolute;
  top: 17px;
  right: 11px;
  z-index: 11;
  border-top: 8px solid #fff;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.select-content {
  margin: 0 30px;
}
#iframeContainer {
  margin: 3em auto 0 auto;
  padding: 0 0.5em;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}
span.current-year {
  display: block;
  float: left;
  color: #18325f;
  font-size: 1.3rem;
  font: 14px/2 "宋体", Arial;
}
ul.comm-list li {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;
  font: 14px/2 "宋体", Arial;
}
ul.comm-list li span:last-child {
  margin-left: 60px;
}
.select-content a {
  color: #666666;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.select-content a:hover {
  color: #e60044;
}
.content div:first-child > div {
  color: #18325f;
  font-size: 1.3rem !important;
  font: 14px/2 "宋体", Arial;
}
</style>
