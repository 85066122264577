var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"banner",style:({ backgroundImage: 'url(' + _vm.banner + ')' }),attrs:{"id":"investor_banner1"}},[_c('span',[_vm._v(_vm._s(_vm.$t("indexnav.Corporate Presentation"))),_c('i',{style:({
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          })},[_vm._v("Corporate Presentation")])])]),_c('section',{staticClass:"ab-item1"},[_c('bread-crumb')],1),_vm._m(0),(false)?_c('section',{staticClass:"content",attrs:{"id":"iframeContainer"}},[_c('div',[_c('div',[_vm._v(_vm._s(_vm.nowYear))]),_c('ul',[_c('li',{on:{"click":_vm.checkyear}},[_c('span',[_vm._v(_vm._s(_vm.$t("responsibility.Select Year")))])]),_c('ul',{style:({
              height: _vm.showyear ? 47 * _vm.yearList.length + 'px' : '0px',
              transition: 'all,0.4s',
            })},_vm._l((_vm.yearList),function(item){return _c('li',{key:item.id,on:{"click":function($event){return _vm.Clickyear(item)}}},[_c('i',[_vm._v(_vm._s(item))])])}),0)])]),_c('div',{staticClass:"select-content"})]):_vm._e(),(false)?_c('section',[_c('div',{staticClass:"select-content"},[_c('ul',{staticClass:"comm-list m-list no-type"},[(_vm.showLoading)?_c('div',{staticClass:"loading_box"}):_vm._e(),_vm._l((_vm.dataList),function(i){return _c('li',{key:i.id},[_c('span',[_vm._v(_vm._s(i.pulishDate))]),_c('span',[_c('a',{attrs:{"href":_vm.filterLang(i, 'FileUrl'),"target":"_blank"}},[_vm._v(_vm._s(_vm.filterLang(i, "Title")))])])])})],2)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{attrs:{"id":"iframeContainer"}})])
}]

export { render, staticRenderFns }